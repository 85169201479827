import "./App.css";
import Hero from "./components/sections/Hero";
import Section2 from "./components/sections/Section-2";
import Section3 from "./components/sections/Section-3";

function App() {
  return (
    <>
      <Hero />
      <Section2 />
      <Section3 />
    </>
  );
}

export default App;
