import React from "react";
import Wrapper from "./style";
import Layout from "../../layout";
import { CardComp } from "../../shared";

const Hero = () => {
  return (
    <Wrapper>
      {/* decoration */}
      <img
        draggable={false}
        src="./assets/decor3.png"
        alt="decor"
        className="top-left"
      />
      <img
        draggable={false}
        src="./assets/lines-top.png"
        alt="decor"
        className="top-right"
      />
      {/* decoration */}

      <Layout>
        <div className="text-highlight">
          <h1
            className="primary-text logo"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              draggable={false}
              fetchPriority="high"
              src="./assets/$.png"
              alt="logo"
            />
            poinko
          </h1>
          &nbsp;&nbsp;
          <h1 className="primary-text"> is now live!</h1>
        </div>

        <div className="row">
          <div className="left">
            <img
              draggable={false}
              src="./assets/POINKO_HERO.png"
              alt="hero-img"
              className="hero-img"
            />
          </div>
          <div className="right">
            <CardComp classNameParent="bg-before" childrenHight={"100%"} />
            <CardComp>
              <h2>
                the famous and notorious poinko brothers in chinese social
                networks
              </h2>

              <div className="text-blue">
                <div
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <h1
                      className="primary-text logo"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        draggable={false}
                        fetchPriority="high"
                        src="./assets/$.png"
                        alt="logo"
                      />
                      poinko
                    </h1>
                    &nbsp; &nbsp;
                    <h1 className="primary-text">is now</h1>
                  </div>
                  <h1 className="primary-text">
                    on
                    <img
                      draggable={false}
                      style={{ marginLeft: "10px", width: "50%" }}
                      src="./assets/Base.svg"
                      alt="base"
                    />
                  </h1>
                </div>
              </div>
              {/* social */}
              <a href="https://twitter.com/PoinkoOnBase">
                <img
                  draggable={false}
                  src="./assets/soc_twit_egg.png"
                  alt="x-socmed"
                  className="soc-x"
                />
              </a>
              <a href="https://t.me/poinkobase">
                <img
                  draggable={false}
                  src="./assets/soc_tele_egg.png"
                  alt="tele-socmed"
                  className="soc-tele"
                />
              </a>
            </CardComp>
          </div>
        </div>
      </Layout>

      <img
        draggable={false}
        src="./assets/bg_bottom_hero.png"
        alt="decor"
        className="bottom-decor"
        style={{
          width: "100%",
          position: "relative",
          zIndex: 2,
        }}
      />
    </Wrapper>
  );
};

export default Hero;
