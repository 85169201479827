const size = {
  mobileP: 375,
  mobileL: 480,
  tabletP: 767,
  tabletL: 991,
  desktopS: 1200,
  desktopM: 1600,
  desktopL: 2560,
};

export const device = {
  mobileP: ` screen and (max-width: ${size.mobileP}px) `,
  mobilePUp: ` screen and (min-width: ${size.mobileP + 1}px) `,
  mobileL: ` screen and (min-width: ${size.mobileP + 1}px) and (max-width: ${
    size.mobileL
  }px) `,
  mobile: ` screen and (max-width: ${size.mobileL}px) `,
  tabletP: ` screen and (min-width: ${size.mobileL + 1}px) and (max-width: ${
    size.tabletP
  }px) `,
  tabletL: ` screen and (min-width: ${size.tabletP + 1}px) and (max-width: ${
    size.tabletL
  }px) `,
  tablet: ` screen and (min-width: ${size.mobileL + 1}px) and (max-width: ${
    size.tabletL
  }px) `,
  desktopS: ` screen and (min-width: ${size.tabletL + 1}px) and (max-width: ${
    size.desktopS
  }px) `,
  desktopM: ` screen and (min-width: ${size.desktopS + 1}px) and (max-width: ${
    size.desktopM
  }px) `,
  desktopL: ` screen and (min-width: ${size.desktopM + 1}px) and (max-width: ${
    size.desktopL
  }px) `,
  desktopXL: ` screen and (min-width: ${size.desktopL + 1}px) `,

  // untuk screen tsb dan lebih kecil
  mobileDown: ` screen and (max-width: ${size.mobileL}px) `,
  tabletPDown: ` screen and (max-width: ${size.tabletP}px) `,
  tabletDown: ` screen and (max-width: ${size.tabletL}px) `,
  desktopSDown: ` screen and (max-width: ${size.desktopS}px) `,

  // untuk screen tsb dan lebih besar
  mobileUp: ` screen and (min-width: ${size.mobileL + 1}px) `,
  tabletUp: ` screen and (min-width: ${size.mobileL + 1}px) `,
  tabletLUp: ` screen and (min-width: ${size.tabletP + 1}px) `,
  desktopUp: ` screen and (min-width: ${size.tabletL + 1}px) `,
  desktopMUp: ` screen and (min-width: ${size.desktopS + 1}px) `,
  desktopLUp: ` screen and (min-width: ${size.desktopM + 1}px) `,
};
