import styled from "styled-components";

const Wrapper = styled.div`
  display: block;
  margin-inline: auto;
  height: 100%;

  @media screen and (min-width: 1600px) {
    width: 1440px;
  }

  @media screen and (min-width: 1201px) and (max-width: 1600px) {
    width: 1150px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 950px;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: calc(100% - 75px);
  }

  @media screen and (min-width: 641px) and (max-width: 767px) {
    width: calc(100% - 60px);
  }

  @media screen and (min-width: 481px) and (max-width: 640px) {
    width: calc(100% - 40px);
  }

  @media screen and (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

export default Wrapper;
