import React from "react";
import Wrapper from "./style";
import { CardComp } from "../../shared";

const Section2 = () => {
  return (
    <Wrapper>
      {/* decoration */}
      <img
        draggable={false}
        src="./assets/top-right-section.png"
        alt="decoration"
        className="top-right-decor"
      />
      {/* decoration */}
      <div className="title">
        <h1 className="primary-text">How to</h1>
        &nbsp;&nbsp;
        <h1
          className="primary-text logo"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            draggable={false}
            fetchPriority="high"
            src="./assets/$.png"
            alt="logo"
          />
          poinko
        </h1>
      </div>
      <div className="row">
        <CardComp classNameParent="card-no-1">
          <img
            draggable={false}
            fetchPriority="high"
            src="./assets/box-no-1.png"
            className="no-1"
            alt="box-no-1"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "-10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1 className="primary-text"> add </h1>
              <img
                draggable={false}
                style={{ marginLeft: "10px", width: "60%" }}
                src="./assets/Base.svg"
                alt="base"
              />
            </div>
            <h1 className="primary-text">to metamask</h1>

            <p>
              Download install the Metamask Wallet either from the App Store on
              your phone or the browser extension for desktop.
            </p>
          </div>
        </CardComp>
        <div className="card-fake" />
      </div>
      <div className="row">
        <div className="card-fake" />
        <CardComp classNameParent="card-no-2">
          <img
            draggable={false}
            fetchPriority="high"
            src="./assets/box-no-2.png"
            className="no-2"
            alt="box-no-2"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "-10px",
            }}
          >
            <h1 className="primary-text">
              LOAD UP <br /> ETH
            </h1>

            <p>
              Head over to bridge.base.org, connect your wallet on the Ethereum
              blockchain. Choose how much ETH you would like to bridge from the
              erc20 network to Base. Remember to leave enough for gas fees
            </p>
          </div>
          <img
            className="box-decor"
            src="./assets/box-decor-2.png"
            alt="decor"
          />
        </CardComp>
      </div>
      <div className="row">
        <CardComp classNameParent="card-no-3">
          <img
            draggable={false}
            fetchPriority="high"
            src="./assets/box-no-3.png"
            className="no-3"
            alt="box-no-3"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "-10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <h1 className="primary-text">buy</h1>
              <h1
                className="primary-text"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  draggable={false}
                  style={{ marginLeft: "10px" }}
                  src="./assets/$.png"
                  alt="$-poinko"
                />
                Poinko
              </h1>
            </div>

            <p>
              Head over to Uniswap and paste the $POINKO contract Address listed
              on this website to swap your ETH.
            </p>
          </div>
        </CardComp>
        <div className="card-fake" />
      </div>
      <div className="row">
        <div className="card-fake" />
        <CardComp classNameParent="card-no-4">
          <img
            draggable={false}
            fetchPriority="high"
            src="./assets/box-no-4.png"
            className="no-4"
            alt="box-no-4"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "-10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <h1 className="primary-text">Add</h1>
              <h1
                className="primary-text"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  draggable={false}
                  style={{ marginLeft: "10px" }}
                  src="./assets/$.png"
                  alt="$-poinko"
                />
                Poinko
              </h1>
            </div>
            <h1 className="primary-text">to your wallet</h1>

            <p>
              Now all you have to do is add the $POINKO contract address to your
              Metamask Wallet for your $POINKO tokens to show. Welcome aboard!
            </p>
          </div>
          <img
            className="box-decor"
            src="./assets/box-decor-2.png"
            alt="decor"
          />
        </CardComp>
      </div>
    </Wrapper>
  );
};

export default Section2;
