import styled from "styled-components";
import { color } from "../../color";
import { device } from "../../media-query";

const Wrapper = styled.section`
  background: url("./assets/bg-section-2.png") no-repeat;
  background-size: 100% 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: calc(100px + 6vw);

  .top-right-decor {
    position: absolute;
    top: -20px;
    right: 0px;
    z-index: 0;
    width: 20rem;
    overflow-x: hidden;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(50px + 0.4vw);
    margin-bottom: calc(20px + 4vw);
    position: relative;
    z-index: 1;

    h1 {
      font-size: 350%;
    }

    img {
      width: 2.2rem;
    }
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: calc(60px + 1vw);
    gap: calc(40px + 5vw);

    h1 {
      font-size: 200%;
      text-align: center;
    }

    p {
      font-family: secondaryfont;
      font-size: 150%;
      color: ${color.textWhite};
      line-height: 25px;
      width: 100%;
      margin: auto;
      text-align: center;
      margin-top: calc(20px + 0.4vw);
    }

    .card-fake {
      position: relative;
      width: 30%;
    }

    .card-no-1 {
      position: relative;
      width: 30%;

      .no-1 {
        width: 5rem;
        position: absolute;
        top: -24px;
        left: -3.2rem;
      }
      &::before {
        z-index: -1;
        content: url(./assets/arrow-1.png);
        position: absolute;
        top: -60px;
        right: -300px;
      }
    }
    .card-no-2 {
      position: relative;
      width: 30%;
      margin-top: -30vh;

      .box-decor {
        position: absolute;
        width: 10rem;
        bottom: -3rem;
        right: 1rem;
      }

      .no-2 {
        width: 8.5rem;
        position: absolute;
        top: -10px;
        right: -60px;
      }
      &::before {
        z-index: -1;
        content: url(./assets/arrow2.png);
        position: absolute;
        bottom: 0px;
        left: -250px;
      }
    }
    .card-no-3 {
      position: relative;
      width: 25%;
      margin-top: -8vh;
      margin-left: 5vw;

      .primary-text {
        img {
          width: 1.3rem;
        }
      }

      .no-3 {
        width: 5rem;
        position: absolute;
        top: -24px;
        left: -3.2rem;
      }
    }
    .card-no-4 {
      position: relative;
      width: 30%;
      margin-top: -20vh;
      margin-right: 5vw;

      .box-decor {
        position: absolute;
        width: 8rem;
        bottom: 3rem;
        left: -6rem;
        transform: rotate(90deg);
      }
      .no-4 {
        width: 120px;
        position: absolute;
        bottom: -20px;
        right: -40px;
      }

      .primary-text {
        img {
          width: 1.3rem;
        }
      }

      &::before {
        z-index: -1;
        content: url(./assets/arrow-3.png);
        position: absolute;
        top: -60px;
        left: -70px;
      }
    }
  }

  @media ${device.desktopSDown} {
    .row {
      .card-no-4 {
        &::before {
          left: -60px;
        }
      }
    }
  }

  @media ${device.tabletPDown} {
    .top-right-decor {
      width: 15rem;
    }
    .title {
      flex-wrap: wrap;
      img {
        width: 1.8rem;
      }
    }
    .row {
      .card-fake {
        display: none;
      }

      .card-no-4,
      .card-no-3,
      .card-no-2,
      .card-no-1 {
        margin-top: 2vh;
        margin-right: 0;
        margin-left: 0;
        width: 60%;

        &:not(.card-no-1)::before {
          position: absolute;
          content: url(./assets/arrow-down.png);
          top: -80px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .card-no-1::before {
        display: none;
      }

      .card-no-2 {
        .box-decor {
          width: 8rem;
          transform: rotate(90deg);
          right: -6rem;
          bottom: 3rem;
        }
      }

      .card-no-2 {
        .no-2 {
          width: 120px;
        }
      }
    }
  }

  @media ${device.mobileDown} {
    background: url("./assets/bg-mobile-section-2.png") no-repeat;
    background-size: cover;

    .top-right-decor {
      width: 12rem;
    }

    .row {
      .card-no-1,
      .card-no-2,
      .card-no-3,
      .card-no-4 {
        width: 70%;
      }
    }
  }
`;
export default Wrapper;
