import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  background-color: #00cbff;
  border: 2px solid #000000;
  padding: calc(8px + 0.4vw) calc(14px + 0.4vw);
  display: flex;
  flex-direction: column;
  height: min-content;

  .buttons-img {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: calc(8px + 0.4vw);
  }

  .content {
    border: 4px double #000000;
    padding: calc(28px + 0.4vw) calc(12px + 0.4vw) calc(12px + 0.4vw);
  }
`;
export default Wrapper;
