import React from "react";
import Wrapper from "./style";

const CardComp = ({
  children,
  classNameParent,
  className,
  backgroundColor,
  contentCustom,
  btnWidth,
  childrenHight,
}) => {
  return (
    <Wrapper className={`${classNameParent ? classNameParent : ""}`}>
      <div className="buttons-img">
        <img
          style={{ width: btnWidth || "30%" }}
          draggable={false}
          src="./assets/buttons.svg"
          alt="buttons-img"
        />
      </div>
      {!contentCustom && (
        <div
          style={{
            backgroundColor: backgroundColor || "#6190e2",
            height: childrenHight || "auto",
          }}
          className={`${className ? className : ""} content`}
        >
          {children}
        </div>
      )}
      {contentCustom}
    </Wrapper>
  );
};

export default CardComp;
