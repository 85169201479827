import styled from "styled-components";
import { color } from "../../color";
import { device } from "../../media-query";

const Wrapper = styled.section`
  background: url("./assets/bg_footer.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding-top: calc(30px + 8vw);

  .decor-1 {
    position: absolute;
    left: 0;
    top: -20rem;
    width: 40rem;
  }
  .decor-2 {
    position: absolute;
    right: 0;
    top: -10rem;
    width: 20rem;
  }

  .title {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    margin-bottom: calc(10px + 3vw);

    h1 {
      font-size: 350%;
      color: ${color.textWhite};
    }

    img {
      width: 2.5rem;
    }
  }
  .content-wrapper {
    position: relative;
    width: 100%;
    margin: auto;

    .bg-before {
      position: absolute;
      top: 1rem;
      right: -2.5rem;
      height: 100%;
      width: 100%;

      .bg-img-red {
        background: url("./assets/bg-red.png") no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
      }
    }
    .content-custom {
      background: url("./assets/bg-white.png") no-repeat;
      background-size: 100% 100%;
      width: 100%;
      .row {
        display: flex;
        margin: calc(10px + 2vw) calc(6px + 1vw);
        margin-left: calc(6px + 4vw);
        gap: calc(20px + 0.4vw);
        .graphic {
          width: 30%;
          height: 30%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .data {
          flex: 1;

          h1 {
            font-family: primaryfont;
            color: ${color.textBlack};
            font-size: 200%;
            text-align: center;
          }
          h2 {
            font-family: primaryfont;
            color: ${color.textBlack};
            font-size: 240%;
            margin-top: calc(20px + 2vw);
          }

          .data-wrapper {
            margin-top: calc(10px + 0.6vw);
            .data-item {
              display: flex;
              align-items: center;
              gap: calc(6px + 0.4vw);

              .dot {
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
              }

              h3 {
                font-family: primaryfont;
                color: ${color.textBlack};
                font-size: 200%;
              }
            }
          }
        }
      }
    }
  }

  footer {
    display: flex;
    flex: 1;
    width: 100%;
    margin-top: 15vh;
    justify-content: flex-end;
    align-items: center;
    padding: calc(20px + 0.6vw) 0;

    .maskot {
      width: 35rem;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .footer-content {
      position: relative;
      z-index: 2;
      h1 {
        font-size: 700%;
        font-family: primaryfont;
        color: white;
        text-shadow: 8px 10px 0px #000000;
        -webkit-text-stroke: 0.2px black;
        text-align: right;
      }
      h2 {
        font-size: 170%;
      }

      .primary-text {
        img {
          width: 1.3rem;
        }
      }

      .soc-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: calc(10px + 0.4vw);
        margin: calc(10px + 0.4vw) 0;

        a {
          transition: 0.4s ease;

          &:hover {
            transform: scale(1.1);
          }
          img {
            width: 150px;
          }
        }
      }

      .logo-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: calc(20px + 0.4vw);

        img {
          width: 18rem;
        }
      }
    }
  }

  @media ${device.desktopSDown} {
    .decor-1 {
      top: -15rem;
      width: 30rem;
    }
    .decor-2 {
      top: -8rem;
      width: 15rem;
    }

    .title {
      img {
        width: 2.3rem;
      }
    }

    .content-wrapper {
      width: 90%;

      .content-custom {
        .row {
          .data {
            h2 {
              font-size: 200%;
            }
          }
        }
      }
    }
  }
  @media ${device.tabletPDown} {
    padding-bottom: 8vh;
    .decor-1 {
      top: -10rem;
      width: 20rem;
    }
    .decor-2 {
      top: -7rem;
      width: 13rem;
    }
    footer {
      .maskot {
        width: 25rem;
      }

      .footer-content {
        .primary-text {
          img {
            width: 1rem;
          }
        }
      }
    }

    .content-wrapper {
      .bg-before {
        right: -1.5rem;
      }

      .content-custom {
        .row {
          flex-direction: column;
          .graphic {
            margin: auto;
            width: 50%;
            height: 50%;
          }

          .data {
            .data-wrapper {
              .data-item {
                h3 {
                  font-size: 150%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media ${device.mobileDown} {
    padding-bottom: 22vh;
    .decor-1 {
      top: -5rem;
      width: 12rem;
    }
    .decor-2 {
      top: -3rem;
      width: 8rem;
    }
    footer {
      .maskot {
        width: 20rem;
        z-index: 0;
      }
    }
  }
`;
export default Wrapper;
