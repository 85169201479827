import React from "react";
import Wrapper from "./style";
import Layout from "../../layout";
import { CardComp } from "../../shared";

const Section3 = () => {
  const data = [
    {
      color: "#5B88C9",
      title: "90% OF SUPPLY TO LP",
    },
    {
      color: "#BCC765",
      title: "7.5% MARKETING",
    },
    {
      color: "#DD1256",
      title: "2.5% TEAM (VESTED FOR I YEAR)",
    },
  ];
  return (
    <Wrapper>
      {/* decoration */}
      <img
        className="decor-1"
        fetchPriority="high"
        src="./assets/decor1.png"
        alt="decor"
        draggable={false}
      />
      <img
        className="decor-2"
        fetchPriority="high"
        src="./assets/decor2.png"
        alt="decor"
        draggable={false}
      />

      {/* decoration */}
      <Layout>
        <div className="title">
          <h1
            className="primary-text logo"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              draggable={false}
              fetchPriority="high"
              src="./assets/$_white.png"
              alt="logo"
            />
            poinko
          </h1>
          &nbsp;&nbsp;
          <h1 className="primary-text">tokenomics</h1>
        </div>
        <div className="content-wrapper">
          <CardComp
            classNameParent="bg-before"
            btnWidth={"8rem"}
            contentCustom={<div className="bg-img-red"></div>}
          />
          <CardComp
            btnWidth={"8rem"}
            contentCustom={
              <div className="content-custom">
                <div className="row">
                  <div className="graphic">
                    <img src="./assets/diagram.png" alt="data" />
                  </div>
                  <div className="data">
                    <h1>CONTRACT IS NOW RENOUNCED</h1>
                    <h2>TOTAL SUPPLY: 1 BILLION TOKEN</h2>

                    <div className="data-wrapper">
                      {data.map((d) => (
                        <div key={d} className="data-item">
                          <div
                            style={{ background: d.color }}
                            className="dot"
                          />
                          <h3>{d.title}</h3>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </Layout>
      <Layout>
        <footer>
          <img
            className="maskot"
            fetchPriority="high"
            src="./assets/maskot.png"
            alt="decor"
            draggable={false}
          />
          <div className="footer-content">
            <h1>socials</h1>
            <h2
              className="primary-text"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              JOIN THE
              <img
                draggable={false}
                style={{ marginLeft: "10px" }}
                src="./assets/$.png"
                alt="$-poinko"
              />
              POINKO COMMUNITY
            </h2>

            <div className="soc-wrapper">
              <a href="https://t.me/poinkobase">
                <img
                  draggable={false}
                  src="./assets/tele-icon.png"
                  alt="telegram"
                />
              </a>
              <a href="https://twitter.com/PoinkoOnBase">
                <img
                  draggable={false}
                  src="./assets/twit-icon.png"
                  alt="telegram"
                />
              </a>
            </div>

            <div className="logo-wrapper">
              <img
                draggable={false}
                src="./assets/POINKO_LOGO.png"
                alt="Poinko"
              />
            </div>
          </div>
        </footer>
      </Layout>
    </Wrapper>
  );
};

export default Section3;
