import styled from "styled-components";
import { color } from "../../color";
import { device } from "../../media-query";

const Wrapper = styled.section`
  background: ${color.background};
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 15rem;
  }
  .top-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 15rem;
  }

  .text-highlight {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
    padding-top: calc(20px + 0.8vw);
    position: relative;
    z-index: 2;

    .logo {
      img {
        width: 2.3rem;
      }
    }
    h1 {
      font-size: 270%;
    }
  }

  .row {
    display: flex;
    gap: calc(20px + 0.4vw);
    align-items: center;
    justify-content: space-between;
    margin-top: calc(20px + 6vw);

    .left {
      position: relative;

      width: 50%;
      .hero-img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      position: relative;
      z-index: 2;
      max-width: 40%;
      .bg-before {
        position: absolute;
        top: -2rem;
        right: -3rem;
        height: 100%;
        width: 100%;
      }
      h2 {
        text-align: center;
        font-family: primaryfont;
        color: ${color.textWhite};
        line-height: 30px;
        margin: auto;
      }

      .text-blue {
        display: flex;
        justify-content: center;
        margin-top: calc(20px + 0.4vw);

        .logo {
          img {
            width: 1.8rem;
          }
        }

        h1 {
          font-size: 240%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }

      // social media
      .soc-x,
      .soc-tele {
        width: 5.5rem;
        position: absolute;
        bottom: -30px;
        transition: 0.4s ease;

        &:hover {
          transform: rotate(10deg);
        }
      }

      .soc-x {
        left: -20px;
      }
      .soc-tele {
        right: -20px;
      }
    }
  }

  @media ${device.desktopSDown} {
    .text-highlight {
      h1 {
        font-size: 300%;
      }
    }
    .row {
      gap: calc(10px + 0.4vw);
      .right {
        max-width: 50%;
        .bg-before {
          right: -2rem;
        }

        .text-blue {
          h1 {
            font-size: 230%;
          }
          .logo {
            img {
              width: 1.5rem;
            }
          }
        }
      }
    }
  }

  @media ${device.tabletPDown} {
    .top-right,
    .top-left {
      width: 10rem;
    }

    .text-highlight {
      .logo {
        img {
          width: 1.5rem;
        }
      }

      h1 {
        font-size: 250%;
      }
    }

    .row {
      flex-direction: column;
      .left {
        order: 2;
        width: 80%;
        margin-top: calc(20px + 0.4vw);
      }
      .right {
        max-width: 75%;

        .soc-x,
        .soc-tele {
          width: 4rem;
        }
      }
    }
  }

  @media ${device.mobileDown} {
    .text-hightlight {
      img {
        width: 30px;
      }
    }
    .row {
      margin-top: calc(40px + 6vw);
      gap: calc(2rem + 2vw);
      .left {
        width: 90%;
      }
      .right {
        max-width: 90%;

        .bg-before {
          right: -1.5rem;
        }
      }
    }
  }
`;
export default Wrapper;
